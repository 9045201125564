import { Box} from '@mui/material';

// @ts-ignore
import './styles.css';
import { useNavigate } from 'react-router-dom';
import { BottomTab } from '../../components/BottomTab';
import { NavBar } from '../../components/NavBar';
import { TextBanner } from '../../components/TextBanner';
import { Typography } from '../../components/Typography';
import { RecipientCard } from '../../components/RecipientCard';
import { Button } from '../../components/Button';
import whiteArrowIcon from "../../assets/arrow.png";
import blackArrowIcon from "../../assets/blackArrow.svg";

export const SelectRecipient = () => {

  const navigate=useNavigate();

  return (
    <Box sx={{display:'flex' , alignItems:'center',  flexDirection: 'column', overflow:'hidden'}}>
      <NavBar
        bgColor="white"
        leftItem="back-button"
        customBackRoute={"/"}
        centerItem="text"
        centerText="select recipient"
        rightItem="help-button"
      />
      <TextBanner heading='did you know?' text='you save more while paying a mewt merchant. Pay only 1% MDR on such transactions.'></TextBanner>
      <Typography 
            variant={"label"}
            size={"x-large"}
            color="#72335D"
            textAlign="left"
            fontFamily="Space Grotesk"
            fontWeight='bold'
            customStyles={ { marginTop: 10, alignSelf: 'flex-start', marginLeft: '7%'}}
          >
            recent recipients
      </Typography>
      <div style={{display:'flex', marginBottom:'5%', alignItems:'center', justifyContent:'center',  flexDirection: 'row'}}>
      <RecipientCard text='Rishabh'></RecipientCard>
      <RecipientCard text='Tarun'></RecipientCard>
      <RecipientCard text='Gurpreet'></RecipientCard>
      <RecipientCard text='Rishi'></RecipientCard>
      </div>
      <Typography
            variant={"label"}
            size={"x-large"}
            color="#72335D"
            textAlign="left"
            fontFamily="Space Grotesk"
            fontWeight='bold'
            customStyles={ {marginTop: 10, alignSelf: 'flex-start', marginLeft: '7%'}}

          >
            all recipients
      </Typography>
      <div style={{display:'flex' , alignItems:'center', justifyContent:'center',  flexDirection: 'row'}}>
      <RecipientCard text='Abhijeet'></RecipientCard>
      <RecipientCard text='Akshat'></RecipientCard>
      <RecipientCard text='Maanav'></RecipientCard>
      <RecipientCard text='Tony'></RecipientCard>
      </div>
      <div style={{display:'flex' , alignItems:'center', justifyContent:'center',  flexDirection: 'row'}}>
      <RecipientCard text='Shreeja'></RecipientCard>
      <RecipientCard text='Rishi'></RecipientCard>
      <RecipientCard text='Vedant'></RecipientCard>
      <RecipientCard text='Anjum'></RecipientCard>
      </div>
      <Button
            text='add recipient'
            size={"medium"}
            onClick={() => navigate("/add-recipient")}
            bgColor={"#F6F6F6"}
            color={"#2A1322"}
            shape="rect"
            width="80%"
            endIcon={<img src={blackArrowIcon} height="18px" />}
            customStyles={ {position:'absolute', bottom: '13%'} }
          />
            <Button
            text='continue'
            size={"medium"}
            onClick={() => navigate("/add-recipient")}
            bgColor={"black"}
            color={"white"}
            shape="rect"
            width="80%"
            endIcon={<img src={whiteArrowIcon} height="18px" />}
            customStyles={ {position:'absolute', bottom: '6%'} }

          />
      {/* <BottomTab></BottomTab> */}
    </Box>
  );
};
