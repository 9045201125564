import React from "react";
/* @import url("https://fonts.googleapis.com/css?family=Space Grotesk"); */

type TypographyProps = {
  variant: "display" | "heading" | "label" | "paragraph" | "bold-heading";
  size: "x-large" | "large" | "medium" | "small" | "extra-small";
  children: string;
  color?: string;
  textAlign?: string;
  text?: string;
  fontFamily?: string;
  fontWeight?: string;
  customStyles?: any;
};

export const Typography = ({
  variant,
  size,
  children,
  color = "#2A1322",
  textAlign,
  fontFamily,
  fontWeight,
  customStyles = {},
}: TypographyProps) => {
  let styles = { ...customStyles };
  styles = { ...styles, color: color };
  switch (variant) {
    case "label":
      styles = {
        ...styles,
        letterSpacing: "0px",
        fontWeight: fontWeight || "500",
        textAlign: textAlign ? textAlign : "left",
        fontFamily: fontFamily ? fontFamily : "DM Sans",
      };
      switch (size) {
        case "x-large":
          styles = { ...styles, fontSize: "20px", lineHeight: "28px" };
          break;
        case "large":
          styles = { ...styles, fontSize: "18px", lineHeight: "24px" };
          break;
        case "medium":
          styles = { ...styles, fontSize: "16px", lineHeight: "20px" };
          break;
        case "small":
          styles = { ...styles, fontSize: "14px", lineHeight: "16px" };
          break;
        case "extra-small":
          styles = { ...styles, fontSize: "12px", lineHeight: "16px" };
          break;
      }
      break;
    case "paragraph":
      styles = {
        ...styles,
        fontWeight: fontWeight || "400",
        fontFamily: fontFamily || "Space Grotesk",
      };
      switch (size) {
        case "large":
          styles = { ...styles, fontSize: "18px", lineHeight: "28px" };
          break;
        case "medium":
          styles = { ...styles, fontSize: "16px", lineHeight: "24px" };
          break;
        case "small":
          styles = { ...styles, fontSize: "14px", lineHeight: "20px" };
          break;
        case "extra-small":
          styles = { ...styles, fontSize: "12px", lineHeight: "20px" };
          break;
      }
      break;
    case "bold-heading":
    case "heading":
      styles = {
        ...styles,
        fontWeight: variant === "heading" ? "500" : "700",
        textAlign: textAlign ? textAlign : "left",
        fontFamily: "Space Grotesk",
      };
      switch (size) {
        case "large":
          styles = { ...styles, fontSize: "32px", lineHeight: "40px" };
          break;
        case "medium":
          styles = { ...styles, fontSize: "28px", lineHeight: "36px" };
          break;
        case "small":
          styles = { ...styles, fontSize: "24px", lineHeight: "32px" };
          break;
        case "extra-small":
          styles = { ...styles, fontSize: "20px", lineHeight: "28px" };
          break;
      }
      break;
    case "display":
      styles = {
        ...styles,
        fontWeight: fontWeight || "500",
        fontFamily: "Space Grotesk",
      };
      switch (size) {
        case "large":
          styles = { ...styles, fontSize: "96px", lineHeight: "112px" };
          break;
        case "medium":
          styles = { ...styles, fontSize: "52px", lineHeight: "64px" };
          break;
        case "small":
          styles = { ...styles, fontSize: "44px", lineHeight: "52px" };
          break;
        case "extra-small":
          styles = { ...styles, fontSize: "36px", lineHeight: "44px" };
          break;
      }
      break;
  }
  return <div style={styles}>{children}</div>;
};
