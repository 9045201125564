import { Box,} from '@mui/material';


// @ts-ignore
import './styles.css';
import addRecipient from './../../assets/addrecipient.png';
import { useNavigate } from 'react-router-dom';
import { BottomTab } from '../../components/BottomTab';
import { NavBar } from '../../components/NavBar';
import { Typography } from '../../components/Typography';
import { Button } from '../../components/Button';
import whiteArrowIcon from "../../assets/arrow.png";
import { Input } from '../../components/Input';
import { useState } from 'react';

export const AddRecipient = () => {

  const navigate=useNavigate();
  const [open, setOpen] = useState(false);

  const doNothing = (event: any) => {
    console.log('do nothing');
    
  const onDismiss = () => {
    setOpen(false);
  };

  };
  return (
    <Box sx={{display:'flex' , alignItems:'center',  flexDirection: 'column', overflow:'hidden'}}>
      <NavBar
        bgColor="white"
        leftItem="back-button"
        customBackRoute={"/"}
        centerItem="text"
        centerText="add recipient"
        rightItem="help-button"
      />
      <Typography 
            variant={"label"}
            size={"x-large"}
            color="#72335D"
            textAlign="left"
            fontFamily="Space Grotesk"
            fontWeight='bold'
            customStyles={ { marginTop: 10, alignSelf: 'flex-start', marginLeft: '7%'}}
          >
            add recipient details
      </Typography>
      <Typography 
            variant={"label"}
            size={"small"}
            color="#6B6B6B"
            textAlign="left"
            fontFamily="Space Grotesk"
            fontWeight='bold'
            customStyles={ { marginBottom: '2%',  alignSelf: 'flex-start', marginLeft: '7%'}}
          >
            add your recipient details here
      </Typography>
      <Typography 
            variant={"label"}
            size={"large"}
            color="#72335D"
            textAlign="left"
            fontFamily="Space Grotesk"
            fontWeight='bold'
            customStyles={ { marginTop: 10, alignSelf: 'flex-start', marginLeft: '7%'}}
          >
            recipient name
      </Typography>
      <Input
        value='ramesh singh'
        label={"recipient name"}
        helperText={"enter the name as it appears on their bank account"}
        onChange={doNothing}
        type="email"
        placeholder=""
        fontFamily='Space Grotesk'
        width={"90vw"}
        maxWidth={"328px"}
        maxLength={12}
      />
      <Typography 
      variant={"label"}
      size={"large"}
      color="#72335D"
      textAlign="left"
      fontFamily="Space Grotesk"
      fontWeight='bold'
      customStyles={ { marginTop: 10, alignSelf: 'flex-start', marginLeft: '7%'}}
    >
      recipient number
      </Typography>
      <Input
        value='9045735826'
        label={"recipient number"}
        helperText={"enter the phone number of the recipient"}
        onChange={doNothing}
        type="email"
        placeholder=""
        fontFamily='Space Grotesk'
        width={"90vw"}
        maxWidth={"328px"}
        maxLength={12}
        /> 
      <Typography 
        variant={"label"}
        size={"large"}
        color="#72335D"
        textAlign="left"
        fontFamily="Space Grotesk"
        fontWeight='bold'
        customStyles={ { marginTop: 10, alignSelf: 'flex-start', marginLeft: '7%'}}
      >
        bank account number
      </Typography>     
      <Input
      value='34561774078'
      label={"bank account number"}
      helperText={"enter the bank account number of the recipient"}
      onChange={doNothing}
      type="email"
      fontFamily='Space Grotesk'
      placeholder=""
      width={"90vw"}
      maxWidth={"328px"}
      maxLength={12}
    />  
    <Typography 
      variant={"label"}
      size={"large"}
      color="#72335D"
      textAlign="left"
      fontFamily="Space Grotesk"
      fontWeight='bold'
      customStyles={ { marginTop: 10, alignSelf: 'flex-start', marginLeft: '7%'}}
    >
      bank IFSC code
      </Typography>    
      <Input
      value='SBIN0000208'
      label={"bank IFSC code"}
      helperText={"enter the bank IFSC code"}
      onChange={doNothing}
      type="email"
      fontFamily='Space Grotesk'
      placeholder=""
      width={"90vw"}
      maxWidth={"328px"}
      maxLength={12}
    />
    <Button
    text='continue'
    size={"medium"}
    onClick={() => navigate("/payment-gateway")}
    bgColor={"black"}
    color={"white"}
    shape="rect"
    width="80%"
    endIcon={<img src={whiteArrowIcon} height="18px" />}
    customStyles={ {position:'absolute', bottom: '6%'} }
      />
      {/* <BottomTab></BottomTab> */}
    </Box>
  );
};
