//@ts-ignore
import { Typography } from "../Typography";
import { To, useNavigate } from "react-router-dom";
import { Button } from "../Button";
//@ts-ignore
import arrowIcon from "../../assets/backArrow.svg";
//@ts-ignore
import helpButton from "../../assets/helpButton.svg";
import "./styles.css";
export const NavBar = ({
  width = "calc(100vw - 32px)",
  leftItem,
  centerItem = "text",
  centerText = "",
  centerWidth = "64px",
  centerHeight = "20px",
  rightItem,
  bgColor = "inherit",
  customBackRoute,
  src,
  screen,
}: {
  width?: string;
  bgColor?: string;
  leftItem?: "back-button" | "account-button";
  centerItem?: "mewt" | "mewt-logo" | "text";
  centerText?: string;
  centerWidth?: string;
  centerHeight?: string;
  rightItem?: "help-button";
  src?: string;
  screen?: string;
  customBackRoute?: To;
}) => {
  const navigate = useNavigate();
  const getLeftItem = () => {
    switch (leftItem) {
      case "back-button":
        return (
          <Button
            bgColor="inherit"
            text=""
            startIcon={<img src={arrowIcon} alt="back" />}
            onClick={() => {
              customBackRoute ? navigate(customBackRoute) : navigate(-1);
            }}
            size="small"
          />
        );
        break;
      default:
        return <div> </div>;
    }
  };

  const getCenterItem = () => {
    switch (centerItem) {
      case "text":
        return (
          <Typography
            variant={"label"}
            size={"small"}
            color="#190B14"
            textAlign="center"
            fontFamily="Space Grotesk"
            fontWeight='bold'
          >
            {centerText}
          </Typography>
        );
      default:
        return <div></div>;
    }
  };

  const getRightItem = () => {
    switch (rightItem) {
      case "help-button":
        return (
          <Button
            bgColor="inherit"
            text=""
            endIcon={<img src={helpButton} alt="back" />}
            onClick={() => {
              navigate("/help");
            }}
            size="small"
          />
        );
        break;
      default:
        return <div></div>;
    }
  };

  return (
    <div
      className="navbar"
      style={{
        width: width,
        backgroundColor: bgColor,
      }}
    >
      <div>{getLeftItem()}</div>
      <div>{getCenterItem()}</div>
      <div>{getRightItem()}</div>
    </div>
  );
};
