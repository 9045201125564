import { Box,} from '@mui/material';


// @ts-ignore
import './styles.css';
import addRecipient from './../../assets/addrecipient.png';
import { useNavigate } from 'react-router-dom';
import { BottomTab } from '../../components/BottomTab';
import { NavBar } from '../../components/NavBar';
import { Typography } from '../../components/Typography';
import { Button } from '../../components/Button';
import whiteArrowIcon from "../../assets/arrow.png";
import shareIcon from "../../assets/shareIcon.png";

import { Input } from '../../components/Input';

import { useState } from 'react';

export const PaymentComplete = () => {

  const navigate=useNavigate();
  const [open, setOpen] = useState(false);

  const doNothing = (event: any) => {
    console.log('do nothing');
    

  };
  return (
    <Box sx={{display:'flex' , alignItems:'center',  flexDirection: 'column', overflow:'hidden'}}>
      <NavBar
        bgColor="white"
        leftItem="back-button"
        customBackRoute={"/"}
        rightItem="help-button"
      />
      <Typography 
            variant={"label"}
            size={"large"}
            color="#72335D"
            textAlign="left"
            fontFamily="Space Grotesk"
            fontWeight='bold'
            customStyles={ { marginTop: 10}}
          >
            You paid
      </Typography>
      <Typography 
      variant={"label"}
      size={"x-large"}
      color="black"
      textAlign="left"
      fontFamily="Space Grotesk"
      fontWeight='bold'
      customStyles={ { marginTop: 10}}
    >
      ₹22,300
      </Typography>
      <Typography 
        variant={"label"}
        size={"medium"}
        color="#6B6B6B"
        textAlign="left"
        fontFamily="Space Grotesk"
        fontWeight='bold'
        customStyles={ { marginTop: 10}}
      >
        to Mayura Veg
      </Typography>     
    <Typography 
      variant={"label"}
      size={"small"}
      color="#6B6B6B"
      textAlign="left"
      fontFamily="Space Grotesk"
      fontWeight='bold'
      customStyles={ { marginTop: 10}}
    >
      18 Jan 2022, 11:40 AM
      </Typography> 
      <Button
    text='share'
    size={"medium"}
    onClick={() => doNothing}
    bgColor={"black"}
    color={"white"}
    shape="pill"
    endIcon={<img src={shareIcon} height="18px" />}
    customStyles={ { marginTop: 10}}
      /> 

    <Typography 
        variant={"label"}
        size={"small"}
        color="#585858B2"
        textAlign="left"
        fontFamily="Space Grotesk"
        fontWeight='bold'
        customStyles={ { marginTop: 20, alignSelf: 'flex-start', marginLeft: '7%'}}
      >
        transaction ID
      </Typography>     
    <Typography 
      variant={"label"}
      size={"large"}
      color="#6B6B6B"
      textAlign="left"
      fontFamily="Space Grotesk"
      fontWeight='bold'
      customStyles={ { marginTop: 10, alignSelf: 'flex-start', marginLeft: '7%'}}
    >
      
      123445675754643
      </Typography>   

      <Typography 
        variant={"label"}
        size={"small"}
        color="#585858B2"
        textAlign="left"
        fontFamily="Space Grotesk"
        fontWeight='bold'
        customStyles={ { marginTop: 20, alignSelf: 'flex-start', marginLeft: '7%'}}
      >
        sent to
      </Typography>     
    <Typography 
      variant={"label"}
      size={"large"}
      color="#6B6B6B"
      textAlign="left"
      fontFamily="Space Grotesk"
      fontWeight='bold'
      customStyles={ { marginTop: 10, alignSelf: 'flex-start', marginLeft: '7%'}}
    >
      
      Mayura Veg
      </Typography>   

      <Typography 
        variant={"label"}
        size={"small"}
        color="#585858B2"
        textAlign="left"
        fontFamily="Space Grotesk"
        fontWeight='bold'
        customStyles={ { marginTop: 20, alignSelf: 'flex-start', marginLeft: '7%'}}
      >
        from 
      </Typography>     
    <Typography 
      variant={"label"}
      size={"large"}
      color="#6B6B6B"
      textAlign="left"
      fontFamily="Space Grotesk"
      fontWeight='bold'
      customStyles={ { marginTop: 10, alignSelf: 'flex-start', marginLeft: '7%'}}
    >
      
      Ramesh Singh
      </Typography>   

      <Typography 
        variant={"label"}
        size={"small"}
        color="#585858B2"
        textAlign="left"
        fontFamily="Space Grotesk"
        fontWeight='bold'
        customStyles={ { marginTop: 20, alignSelf: 'flex-start', marginLeft: '7%'}}
      >
        mewt transaction ID
      </Typography>     
    <Typography 
      variant={"label"}
      size={"large"}
      color="#6B6B6B"
      textAlign="left"
      fontFamily="Space Grotesk"
      fontWeight='bold'
      customStyles={ { marginTop: 10, alignSelf: 'flex-start', marginLeft: '7%'}}
    >
      
      123445675754643
      </Typography>    

    <Button
    text='continue to transactions'
    size={"medium"}
    onClick={() => navigate("/transactions")}
    bgColor={"black"}
    color={"white"}
    shape="rect"
    width="80%"
    endIcon={<img src={whiteArrowIcon} height="18px" />}
    customStyles={ {position:'absolute', bottom: '6%'} }
      />
      {/* <BottomTab></BottomTab> */}
    </Box>
  );
};
