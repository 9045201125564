export const styles = {
  button: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "5px 8px",
  },
  text: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "24px",
    flex: "none",
  },
};
