import React from "react";
import { Typography } from "../Typography";
import "./styles.css";
export const Loader = ({
  text,
  size = "medium",
}: {
  text?: string;
  size?: "large" | "medium" | "small" | "extra-small";
}) => {
  let scale = 1;
  switch (size) {
    case "extra-small":
      scale = 1;
      break;
    case "small":
      scale = 3 / 2;
      break;
    case "medium":
      scale = 2;
      break;
    case "large":
      scale = 8 / 3;
      break;
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <div
        className={`loader`}
        style={{ height: `${24 * scale}px`, width: `${24 * scale}px` }}
      ></div>
      {text && (
        <div style={{ marginTop: 16 }}>
          <Typography variant={"heading"} size={"extra-small"}>
            {text}
          </Typography>
        </div>
      )}
    </div>
  );
};
