import { Box,} from '@mui/material';


// @ts-ignore
import './styles.css';
import addRecipient from './../../assets/addrecipient.png';
import { useNavigate } from 'react-router-dom';
import { BottomTab } from '../../components/BottomTab';
import { NavBar } from '../../components/NavBar';
import { Typography } from '../../components/Typography';
import { Button } from '../../components/Button';
import whiteArrowIcon from "../../assets/arrow.png";
import shareIcon from "../../assets/shareIcon.png";

import { Input } from '../../components/Input';

import { useState } from 'react';

export const PaymentGateway = () => {

  const navigate=useNavigate();
  const [open, setOpen] = useState(false);

  const doNothing = (event: any) => {
    console.log('do nothing');
    

  };
  return (
    <Box sx={{display:'flex' , alignItems:'center',  flexDirection: 'column', overflow:'hidden'}}>

      <Typography 
      variant={"label"}
      size={"x-large"}
      color="black"
      textAlign="left"
      fontFamily="Space Grotesk"
      fontWeight='bold'
      customStyles={ { marginTop: '50%'}}
    >
      payment gateway redirect
      </Typography>       

    <Button
    text='continue'
    size={"medium"}
    onClick={() => navigate("/payment-complete")}
    bgColor={"black"}
    color={"white"}
    shape="rect"
    width="80%"
    endIcon={<img src={whiteArrowIcon} height="18px" />}
    customStyles={ {position:'absolute', bottom: '6%'} }
      />
      {/* <BottomTab></BottomTab> */}
    </Box>
  );
};
