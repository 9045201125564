import { Box, Button} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {faWavePulse, faHouse} from '@fortawesome/sharp-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome';
import { Typography } from '../Typography';

type RecipientCardProps = {
  text?: string;
};

export const RecipientCard = ({
  text = "hello",
}: RecipientCardProps) => {

  const navigate = useNavigate();

  return (
    <Box sx={{display:'flex' , alignItems:'center', justifyContent:'center',  flexDirection: 'column'}}>
      <div onClick={() => navigate("/payment-gateway")} style={{margin: 15, padding: 30, height:'80%',  backgroundColor: 'gray',  borderRadius: 30}}>
      </div>
      <Typography
            variant={"label"}
            size={"medium"}
            color="#190B14"
            textAlign="left"
            fontFamily="Space Grotesk"
          >
            {text}
      </Typography>
    </Box>
  );
};
