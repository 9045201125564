import { motion } from "framer-motion";
import  { ReactNode,} from "react";
import { Typography } from "../Typography";

import { styles } from "./styles";
export const Button = ({
  size,
  text,
  onClick,
  bgColor = "#190B14",
  startIcon,
  endIcon,
  width,
  maxWidth,
  shape = "rect",
  color = "#ffffff",
  loading,
  disabled = false,
  fontFamily = "Space Grotesk",
  customStyles = {},
  justifyContent = "space-between",
}: {
  text: string;
  size: "large" | "medium" | "small" | "extra-small";
  onClick: () => void;
  bgColor: string;
  color?: string;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  width?: string;
  maxWidth?: string;
  shape?: "pill" | "rect";
  loading?: boolean;
  disabled?: boolean;
  fontFamily?: string;
  customStyles?: any;
  justifyContent?:
    | "center"
    | "start"
    | "end"
    | "flex-start"
    | "flex-end"
    | "left"
    | "right"
    | "space-between";
}) => {
  let style = { ...customStyles };
  switch (shape) {
    case "pill":
      style = { ...style, borderRadius: 36 };
      break;
    case "rect":
      style = { ...style, borderRadius: 0 };
  }
  switch (size) {
    case "extra-small":
      style = { ...style, height: "2px" };
      break;
    case "small":
      style = { ...style, height: "36px" };
      break;
    case "medium":
      style = { ...style, height: "48px" };
      break;
    case "large":
      style = { ...style, height: "56px" };
      break;
  }
  style = {
    ...style,
    backgroundColor: bgColor,
    width: width,
    maxWidth: maxWidth,
    border: bgColor,
  };
  let params = {};
  if (!disabled) {
    params = {
      ...params,
      whileTap: { scale: 0.9 },
    };
  } else {
    style = { ...style, opacity: "40%" };
  }
  return (
    <>
        <motion.button
          style={{
            ...styles.button,
            ...style,
            justifyContent: justifyContent,
            columnGap: "5px",
          }}
          disabled={disabled}
          {...params}
          onClick={onClick}
        >
          <div>{startIcon}</div>
          <Typography
            variant={"label"}
            size={size === "small" ? "small" : "medium"}
            color={color}
            fontFamily={fontFamily}
          >
            {text}
          </Typography>
          <div>{endIcon}</div>
        </motion.button>
    </>
  );
};
