import React, { ReactNode, useEffect, useState } from "react";
import { Typography } from "../Typography";
interface InputProps {
  onChange: (e:any) => void;
  value: string | number;
  type: "tel" | "text" | "email";
  label?: string;
  helperText?: string;
  placeholder?: string;
  pattern?: string;
  disabled?: boolean;
  width?: string;
  maxLength?: number;
  maxWidth?: string;
  endIcon?: ReactNode;
  error?: boolean;
  success?: boolean;
  ref?: any;
  onKeyDown?: (e:any) => void;
  onFocus?: (e:any) => void;
  autoFocus?: boolean;
  keyboardType?: "tel" | "text" | "number";
  autoCapitalize?: "sentences" | "words" | "characters" | "on" | "off";
  fontWeight?: "500";
  fontFamily?: string;
}
export const Input = (props: InputProps) => {
  const {
    onChange,
    onKeyDown,
    value,
    type,
    label,
    helperText,
    placeholder,
    pattern,
    disabled,
    width,
    maxWidth,
    endIcon,
    error,
    success,
    ref,
    maxLength,
    keyboardType,
    autoFocus = false,
    autoCapitalize,
    onFocus,
    fontWeight,
    fontFamily,
  } = props;
  const [focus, setFocus] = useState(autoFocus);

  useEffect(() => {
    setFocus(autoFocus);
  }, [autoFocus]);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: width,
        maxWidth: maxWidth,
      }}
    >
      <label></label>
      {type === "tel" ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            paddingLeft: 0,
            paddingRight: 0,
            width: "100%",
            position: "relative",
          }}
        >
          <input
            style={{
              marginTop: 8,
              marginRight: 8,
              padding: 16,
              marginBottom: 8,
              fontFamily: "Space Grotesk",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "16px",
              backgroundColor: "#F6F6F6",
              border: 0,
              width: 35,
            }}
            onChange={onChange}
            pattern={pattern}
            value={"+91"}
            type={type}
            placeholder={"+91"}
            disabled={true}
          />
          <input
            style={{
              marginTop: 8,
              padding: 16,
              paddingRight: 0,
              marginBottom: 8,
              fontFamily: "Space Grotesk",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "16px",
              lineHeight: "16px",
              outline: 0,
              border: error
                ? "2px solid red"
                : success
                ? "2px solid green"
                : focus
                ? "2px solid #000000"
                : "2px solid transparent",
              background: error ? "#FFEFED" : success ? "#E6F2ED" : "#F6F6F6",
              flex: 1,
            }}
            data-testid="phone"
            onChange={onChange}
            pattern={pattern}
            value={value}
            type={type}
            placeholder={placeholder}
            disabled={disabled}
            maxLength={10}
            onFocus={(e) => {
              onFocus && onFocus(e);
              setFocus(true);
            }}
            onBlur={() => {
              setFocus(false);
            }}
            autoFocus={true}
          ></input>
          <div style={{ position: "absolute", top: "1.5em", right: "5px" }}>
            {endIcon}
          </div>
        </div>
      ) : (
        <div
          style={{
            // position: "relative",
            display: "flex",
            flexDirection: "row",
            paddingLeft: 0,
            paddingRight: 0,
            width: "100%",
            position: "relative",
          }}
        >
          <input
            style={{
              marginTop: 8,
              padding: 16,
              marginBottom: 8,
              fontFamily: "Space Grotesk",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "16px",
              outline: 0,
              border: error
                ? "2px solid red"
                : success
                ? "2px solid green"
                : focus
                ? "2px solid #000000"
                : "2px solid transparent",
              background: error ? "#FFEFED" : success ? "#E6F2ED" : "#F6F6F6",
              width: "100%",
            }}
            ref={ref}
            onChange={onChange}
            onFocus={(e) => {
              onFocus && onFocus(e);
              setFocus(true);
            }}
            onBlur={() => {
              setFocus(false);
            }}
            autoFocus={autoFocus}
            pattern={pattern}
            value={value}
            type={keyboardType ? keyboardType : type}
            placeholder={placeholder}
            disabled={disabled}
            maxLength={maxLength}
            onKeyDown={onKeyDown}
            autoCapitalize={autoCapitalize ? autoCapitalize : "words"}
            data-testid={label}
          />
          <div style={{ position: "absolute", top: "1.5em", right: "5px" }}>
            {endIcon}
          </div>
        </div>
      )}
      {helperText && (
        <div style={{ marginTop: "0.2em", marginBottom: "0.2em" }}>
          <Typography
            variant={"label"}
            size={"small"}
            color={"#6B6B6B"}
            fontFamily={fontFamily}
          >
            {helperText}
          </Typography>
        </div>
      )}
    </div>
  );
};
