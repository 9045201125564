import { Box} from '@mui/material';

// @ts-ignore
import './styles.css';
import SelectRec from './../../assets/transactions.png';
// import { useNavigate } from 'react-router-dom';
import { BottomTab } from '../../components/BottomTab';
import { Button } from '../../components/Button';
import { useNavigate } from 'react-router-dom';
import whiteArrowIcon from "../../assets/arrow.png";

export const Transactions = () => {

  const navigate = useNavigate();

  return (
    <Box sx={{display:'flex' , alignItems:'center',  flexDirection: 'column'}}>
      <img src={SelectRec} alt='Mewt' style={{ width: '100%', marginBottom:'1%' }} />
      <Button
    text='pay another'
    size={"medium"}
    onClick={() => navigate("/pay-amount")}
    bgColor={"black"}
    color={"white"}
    shape="rect"
    width="80%"
    endIcon={<img src={whiteArrowIcon} height="18px" />}
    customStyles={ {position:'absolute', bottom: '6%'} }
      />
      {/* <BottomTab></BottomTab> */}
    </Box>
  );
};
