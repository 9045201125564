import { Box, Button} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {faWavePulse, faHouse} from '@fortawesome/sharp-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome';
import { Typography } from '../Typography';

type TextBannerProps = {
  text?: string;
  heading?: string;
};

export const TextBanner = ({
  text = "text",
  heading = "heading"
}: TextBannerProps) => {

  const navigate = useNavigate();

  return (
    <Box sx={{display:'flex' , alignItems:'center', justifyContent:'center'}}>
      <div style={{padding: '5%', marginTop:'5%', marginBottom:'5%',  width: '80%', borderWidth: 2, backgroundColor: '#FFC043', display:'flex',   flexDirection: 'column', alignItems:'flex-start', borderRadius: 5}}>
      <Typography
            variant={"label"}
            size={"large"}
            color="#190B14"
            textAlign="left"
            fontFamily="Space Grotesk"
            fontWeight='bold'
          >
            {heading}
      </Typography>
      <Typography
            variant={"label"}
            size={"large"}
            color="#190B14"
            textAlign="left"
            fontFamily="Space Grotesk"
          >
            {text}
      </Typography>
      </div>
    </Box>
  );
};
